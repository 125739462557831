// @flow
import * as React from 'react'
import styled from 'react-emotion'
import { css } from 'emotion'
import range from 'lodash/range'
import { Waypoint } from 'react-waypoint'
import TWEEN from '@tweenjs/tween.js'

type Props = {
  children: React.Node,
  className?: string,
  style?: any,
  onClick: any
}

const Wrapper = styled.div`
  position: relative;
  user-select: none;
`

const Carousel = styled.div`
  overflow: hidden;
  user-select: none;
  outline: none;
  position: relative;
  &:hover {
    cursor: grab;
  }
  &:active {
    cursor: grabbing;
  }
`

const ContainerContent = styled.div`
  display: flex;
  position: relative;
  user-select: none;
`

export default class CarouselWrapper extends React.Component<Props> {
  offsetX = 0
  containerContent: ?HTMLDivElement
  carousel: ?HTMLDivElement
  mouseDown = false
  timeConstant = 325
  childDisplacement = 0
  velocity = 0
  amplitude = 0
  frame = 0
  touched = false
  ticker: any
  clickTime: number
  lastMousePos: { x: number, y: number }
  timestamp: number
  target: number
  childCount: number
  hasJustStarted = false
  shouldAnimate = false
  hasEntered = false

  constructor(props: Props) {
    super(props)
    this.childCount = React.Children.count(props.children)
  }

  componentDidMount() {
    this.calculateOffset()
    window.addEventListener('resize', this.calculateOffset)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.calculateOffset)
    clearInterval(this.ticker)
  }

  calculateOffset = () => {
    if (!this.touched) {
      let windowWidth = window.innerWidth
      let maxWidth = 960
      let offsetLeft = maxWidth > windowWidth ? 0 : (windowWidth - maxWidth) / 2
      this.setPosition(-(this.childWidth - offsetLeft))
    }
  }

  get childWidth(): number {
    let windowSize = typeof window !== 'undefined' ? window.innerWidth : 800
    if (windowSize > 1920) return 1120
    if (windowSize > 1680) return 980
    if (windowSize > 1280) return 940
    if (windowSize > 1040) return 840
    if (windowSize > 800) return 720
    return windowSize
  }

  get childrenWidth(): number {
    return this.childWidth * this.childCount
  }

  get necessaryChildCount(): number {
    return typeof window === 'undefined'
      ? 2
      : Math.ceil(window.innerWidth / this.childWidth) + 1
  }

  get necessaryDOMChildCount(): number {
    return Math.max(
      2 * this.childCount,
      Math.ceil(this.necessaryChildCount / this.childCount) * this.childCount
    )
  }

  getPos(ev: any) {
    if (ev.targetTouches && ev.targetTouches.length >= 1) {
      return { x: ev.targetTouches[0].clientX, y: ev.targetTouches[0].clientY }
    }

    return { x: ev.clientX, y: ev.clientY }
  }

  setPosition = (x: number) => {
    this.offsetX = x

    this.childDisplacement =
      -(Math.floor(this.offsetX / this.childrenWidth) * this.childrenWidth) -
      this.childrenWidth

    if (this.containerContent) {
      this.containerContent.style.transform = `translate3d(${this.offsetX +
        this.childDisplacement}px, 0, 0)`
    }
  }

  initMove = (ev: any) => {
    if (!this.touched) this.touched = true
    this.clickTime = Date.now()
    this.mouseDown = true
    this.lastMousePos = this.getPos(ev)

    this.velocity = 0
    this.amplitude = 0
    this.frame = this.offsetX
    this.timestamp = Date.now()
    clearInterval(this.ticker)
    this.ticker = setInterval(this.track, 100)
  }

  handleMouseDown = (ev: any) => {
    this.initMove(ev)
    ev.preventDefault()
    ev.stopPropagation()
    return false
  }

  handleTouchDown = (ev: any) => {
    this.initMove(ev)
    this.hasJustStarted = true
  }

  track = () => {
    const now = Date.now()
    const elapsed = now - this.timestamp
    const delta = this.offsetX - this.frame
    this.timestamp = now
    this.frame = this.offsetX

    const v = (1000 * delta) / (1 + elapsed)
    this.velocity = 0.8 * v + 0.2 * this.velocity
  }

  handleMouseMove = (ev: any) => {
    if (this.mouseDown) {
      let mousePos = this.getPos(ev)
      let deltaX = mousePos.x - this.lastMousePos.x
      let deltaY = mousePos.y - this.lastMousePos.y
      let absX = Math.abs(deltaX)

      if (this.hasJustStarted) {
        if (Math.abs(deltaY) > absX + 4) {
          this.mouseDown = false
          clearInterval(this.ticker)
          return false
        }
        this.hasJustStarted = false
      }
      if (absX > 2) {
        this.setPosition(this.offsetX + deltaX)
        this.lastMousePos = mousePos
      }
      ev.preventDefault()
      ev.stopPropagation()
      return false
    }
  }

  handleMouseUp = (ev: any) => {
    if (this.mouseDown) {
      this.mouseDown = false
      clearInterval(this.ticker)
      this.track()

      let now = Date.now()

      if (this.velocity > 10 || this.velocity < -10) {
        this.amplitude = 0.3 * this.velocity
        this.target = Math.round(this.offsetX + this.amplitude)
        this.timestamp = now
        requestAnimationFrame(this.autoScroll)
      } else if (now - this.clickTime < 200) {
        this.props.onClick()
      }
    }
    ev.preventDefault()
    ev.stopPropagation()
    return false
  }

  autoScroll = () => {
    let elapsed, delta
    if (this.amplitude) {
      elapsed = Date.now() - this.timestamp
      delta = -this.amplitude * Math.exp(-elapsed / this.timeConstant)
      if (delta > 0.5 || delta < -0.5) {
        this.setPosition(this.target + delta)
        requestAnimationFrame(this.autoScroll)
      } else {
        this.setPosition(this.target)
      }
    }
  }

  positiveModulo(num: number, mod: number) {
    const result = num % mod
    if (result < 0) {
      return result + mod
    } else {
      return result
    }
  }

  animate = () => {
    if (this.shouldAnimate) requestAnimationFrame(this.animate)
    TWEEN.update()
  }

  _handleWaypointEnter = ({
    previousPosition
  }: {
    previousPosition: string
  }) => {
    if (this.hasEntered) return false
    this.hasEntered = true
    if (previousPosition !== 'below') return false
    this.shouldAnimate = true
    let originalOffset = { x: this.offsetX }
    this.offsetX = this.offsetX + window.innerWidth * 0.3
    let offsetOffset = { x: this.offsetX }

    new TWEEN.Tween(offsetOffset)
      .to({ x: originalOffset.x }, 1000)
      .easing(TWEEN.Easing.Cubic.Out)
      .onUpdate(({ x }) => this.setPosition(x))
      .onComplete(() => (this.shouldAnimate = false))
      .start()
    this.animate()
  }

  render() {
    const { className, style } = this.props
    const oldChildren = React.Children.toArray(this.props.children)
    const children = range(this.necessaryDOMChildCount).map((index, i) => {
      return React.cloneElement(oldChildren[index % this.childCount], {
        key: `${oldChildren[index % this.childCount].key}-${i}`,
        className: css`
          flex: 0 0 auto;
        `
      })
    })

    return (
      <Wrapper className={className} style={style}>
        <Waypoint fireOnRapidScroll={false} onEnter={this._handleWaypointEnter}>
          <Carousel
            onMouseLeave={this.handleMouseUp}
            onMouseMove={this.handleMouseMove}
            onTouchMove={this.handleMouseMove}
            onMouseUp={this.handleMouseUp}
            onTouchEnd={this.handleMouseUp}
            onTouchStart={this.handleTouchDown}
            onMouseDown={this.handleMouseDown}
            innerRef={c => (this.carousel = c)}
          >
            <ContainerContent innerRef={c => (this.containerContent = c)}>
              {children}
            </ContainerContent>
          </Carousel>
        </Waypoint>
      </Wrapper>
    )
  }
}
