// @flow
import * as React from 'react'
import { Link } from 'gatsby'
import { css } from 'emotion'
import styled from 'react-emotion'
import { navigate } from '@reach/router'

import { Content, Lead, spacing } from './Theme'
import Carousel from './Carousel'

type Props = {
  data: {
    projects: {
      edges: Array<{
        node: {
          title: string,
          tagline: string,
          client: string,
          featured: boolean,
          tags: Array<string>,
          slug: string,
          images: Array<{
            childImageSharp: {
              resize: {
                src: string
              }
            }
          }>
        }
      }>
    },
    category: {
      slug: string
    }
  }
}

const Project = styled.div`
  margin-bottom: 8em;
  @media screen and (max-width: 800px) {
    margin-bottom: 5em;
  }
`

const ProjectLink = css``

const Title = styled.h2`
  margin-bottom: 0.25em;
`

const ThumbnailWrapper = styled.div`
  width: 100%;
  padding: 0 0.5em;
  max-width: 1120px;
  height: 500px;
  user-select: none;
  position: relative;
  @media screen and (max-width: 1920px) {
    max-width: 980px;
  }
  @media screen and (max-width: 1680px) {
    max-width: 940px;
    height: 480px;
  }
  @media screen and (max-width: 1280px) {
    max-width: 840px;
    height: 440px;
  }
  @media screen and (max-width: 1040px) {
    max-width: 720px;
    height: 400px;
  }
  @media screen and (max-width: 800px) {
    max-width: 100%;
    height: 300px;
  }
`

const Thumbnail = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgb(245, 245, 245);
  background-size: cover;
  background-position: center center;
`

const Tags = styled.ul`
  list-style-type: none;
  display: flex;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0;
`

const Tag = styled.li`
  font-size: 0.9em;
  flex: 0 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: rgb(180, 180, 190);
  text-transform: uppercase;
`

const Client = styled.li`
  font-size: 0.9em;
  color: rgb(70, 70, 90);
  text-transform: uppercase;
  margin-bottom: 1em;
  &:after {
    content: ' — ';
    color: rgb(195, 195, 200);
    margin: 0 0.5em;
    display: inline-block;
  }
`

export default (props: Props) => {
  const {
    data: { category, projects }
  } = props
  return (
    <div>
      {projects &&
        projects.edges.map(({ node }, index) => {
          const project_path = `${category.slug}/${node.slug}`
          return (
            <Project key={`project-${node.slug}-${index}`}>
              <Content
                css={`
                  margin-bottom: ${spacing.small};
                `}
              >
                <Link className={ProjectLink} to={project_path}>
                  <Title>{node.title}</Title>
                  <Tags>
                    <Client>{node.client}</Client>
                    <Tag>{node.tags && node.tags.join(' / ')}</Tag>
                  </Tags>

                  <Lead>
                    <p>{node.tagline}</p>
                  </Lead>
                </Link>
              </Content>

              {node.images &&
                node.images.length > 0 && (
                  <Carousel onClick={() => navigate(project_path)}>
                    {node.images.map((image, index) => (
                      <ThumbnailWrapper key={`thumbnail-${node.slug}-${index}`}>
                        <Thumbnail
                          style={{
                            backgroundImage: `url(${image.resize.src})`
                          }}
                        />
                      </ThumbnailWrapper>
                    ))}
                  </Carousel>
                )}
            </Project>
          )
        })}
    </div>
  )
}
