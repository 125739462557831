// @flow
import * as React from 'react'
import { graphql } from 'gatsby'

import CategoryPage from '../components/CategoryPage'
import Header from '../components/Header'
import { Meta } from '../components/Theme'
import Layout from '../components/Layout'

type Props = {
  data: {
    category: any,
    projects: {
      edges: Array<{
        node: {
          frontmatter: {
            title: string,
            tagline: string,
            client: string,
            featured: boolean,
            tags: Array<string>,
            images: Array<{
              childImageSharp: {
                resize: {
                  src: string
                }
              }
            }>
          },
          fields: {
            slug: string
          },
          id: string
        }
      }>
    }
  },
  location: any
}

export default (props: Props) => {
  const category = props.data.category
  return (
    <Layout location={props.location}>
      <div>
        <Meta title={category.name} />
        <Header
          title={props.data.category.name}
          htmlIntroduction={
            category.introduction &&
            category.introduction.markdown &&
            category.introduction.markdown.html
          }
        />
        <CategoryPage {...props} />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query CategoryQuery($slug: String!) {
    category: contentfulCategory(slug: { eq: $slug }) {
      name
      slug
      introduction {
        markdown: childMarkdownRemark {
          html
        }
      }
    }
    projects: allContentfulProject(
      filter: { category: { slug: { eq: $slug } } }
      sort: { fields: [date], order: DESC }
    ) {
      totalCount
      edges {
        node {
          id
          title
          slug
          tagline
          client
          featured
          tags
          images {
            resize(width: 960, height: 500, cropFocus: LEFT, quality: 85) {
              src
            }
          }
        }
      }
    }
  }
`
